@charset "UTF-8";

@font-face {
  font-family: "chmshq";
  src:url("fonts/chmshq.eot");
  src:url("fonts/chmshq.eot?#iefix") format("embedded-opentype"),
    url("fonts/chmshq.woff") format("woff"),
    url("fonts/chmshq.ttf") format("truetype"),
    url("fonts/chmshq.svg#chmshq") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "chmshq" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "chmshq" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dashboard:before {
  content: "\61";
}
.icon-company:before {
  content: "\62";
}
.icon-activity:before {
  content: "\63";
}
.icon-events:before {
  content: "\64";
}
.icon-group:before {
  content: "\65";
}
.icon-member:before {
  content: "\66";
}
.icon-settings:before {
  content: "\68";
}
.icon-user:before {
  content: "\69";
}
.icon-reports:before {
  content: "\6a";
}
.icon-communication:before {
  content: "\6c";
}
.icon-project:before {
  content: "\67";
}
.icon-contrast:before {
  content: "\6b";
}
.icon-document-text:before {
  content: "\6d";
}
.icon-family:before {
  content: "\6e";
}
.icon-birthday:before {
  content: "\6f";
}
.icon-graduation:before {
  content: "\70";
}
.icon-home:before {
  content: "\71";
}
.icon-phone:before {
  content: "\72";
}
.icon-link:before {
  content: "\73";
}
.icon-email:before {
  content: "\74";
}
.icon-relation:before {
  content: "\75";
}
.icon-user-circle:before {
  content: "\76";
}
.icon-location:before {
  content: "\77";
}
.icon-facebook:before {
  content: "\78";
}
.icon-twitter:before {
  content: "\79";
}
.icon-linkedin:before {
  content: "\7a";
}
.icon-youtube:before {
  content: "\41";
}
.icon-instagrem:before {
  content: "\42";
}
.icon-whatsapp:before {
  content: "\43";
}
.icon-share:before {
  content: "\44";
}
.icon-other:before {
  content: "\45";
}
.icon-child:before {
  content: "\46";
}
.icon-person:before {
  content: "\47";
}
.icon-star:before {
  content: "\48";
}
.icon-certificate:before {
  content: "\49";
}
